import styled from 'styled-components';

export const AnchorStyle = styled.a`
  text-decoration: none;
  color: #1fb25a;
  :hover {
    text-decoration: none;
    color: #0397d6;
  }
`;
export const ParaText = styled.p`
  margin: 0 0 30px 0;
  padding: 0;
  b {
    font-family: 'Gotham-Bold', arial, sans-serif;
    font-weight: bold;
  }
`;

export const ImageStyle = styled.img`
  float: left;
  width: auto;
  height: auto;
  margin: 0 20px 20px 0;
  border: 0;
  vertical-align: top;
  @media (max-width: 399px) {
    width: 100% !important;
    height: auto !important;
  }
`;

export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  font-size: 28px;
  line-height: 35px;
  color: #008752;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;
